import React, { ReactElement } from "react";
import Link from "next/link";
import TortsLogo from "@components/shared/header/logo";
import styles from "./style.module.scss";

interface Link {
    href?: string;
    onClick?: () => void;
    label: string;
}
const links: Link[] = [
    { label: "Privacy Policy", href: "/privacy-policy" },
    { href: "/terms-and-conditions", label: "Terms & Conditions" },
    { label: "Join Us", href: "/join-us" },
];

export default function Footer(): ReactElement {
    return (
        <footer className="flex relative bg-white z-50">
            <div className="max-w-7xl mx-auto w-full px-2 py-4 flex flex-col gap-4 items-center md:items-start p-6">
                <TortsLogo color="black" />
                <div className="w-full flex flex-col-reverse md:flex-row justify-between items-center">
                    <div className="text-black md:text-[#898989] mb-5 md:mb-0 mt-5 md:mt-0 text-sm font-thin">
                        ©Copyright {new Date().getFullYear()} | All Rights
                        Reserved
                    </div>
                    <div>
                        <ul className="flex gap-2 font-light">
                            {links.map((link) => (
                                <li
                                    onClick={link.onClick}
                                    className={styles["footer-link"]}
                                    key={link.label}
                                >
                                    <Link href={link.href as string}>
                                        <a>{link.label}</a>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}
