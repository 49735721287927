import { ReactElement } from "react";
import schema from "./schema";

const Schema = (): ReactElement => {
    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(schema),
            }}
        />
    );
};
export default Schema;
