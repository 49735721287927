/* eslint-disable @next/next/no-document-import-in-page */
/* eslint-disable @next/next/no-title-in-document-head */
import React, { ReactNode } from "react";
import Header from "@components/shared/header";
import Footer from "@components/shared/footer";
import { useQuery } from "@tanstack/react-query";
import Head from "next/head";
// import TrustedFormScript from "@components/scripts/trustedFormScript";
import GtmScript from "@components/scripts/gtmScript";
import { GQLDomainQuery } from "@gql/types/gql";
import Schema from "@components/scripts/schema";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import FavIcons from "@components/shared/favIcon";

interface RecaptchaConfig {
    key: string;
    secret: string;
}

export default function DefaultLayout({ children }: { children: ReactNode }) {
    const { data: domainInfo } = useQuery<GQLDomainQuery>({
        queryKey: ["domain"],
    });

    const { gtmId, description } = domainInfo?.domain || {
        enableJornayaId: false,
        enableTrustedForm: false,
        gtmId: 0,
        description: "",
        isInspectorEnabled: false,
        niceName: "",
        title: "",
        metatags: [],
    };

    const isBot = false;

    const recaptchaConfig = domainInfo?.domain
        ?.recaptchaConfig as RecaptchaConfig;

    return (
        <div>
            <Head>
                <Schema />
                {gtmId && !isBot && <GtmScript gtmId={gtmId as string} />}
                {description && (
                    <meta name="description" content={description} />
                )}
                <FavIcons />
            </Head>

            <GoogleReCaptchaProvider
                reCaptchaKey={
                    recaptchaConfig?.key ??
                    (process.env
                        .NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY as string)
                }
                container={{
                    parameters: {
                        badge: "inline", // optional, default undefined
                        theme: "dark", // optional, default undefined
                    },
                }}
            >
                <Header />
                <main>{children}</main>
                <Footer />
            </GoogleReCaptchaProvider>
        </div>
    );
}
