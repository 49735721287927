import { getAccessToken } from "@services/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface SessionResponse {
    success: boolean;
    data: Session;
}

export interface Session {
    accessToken: string | null;
    visitDetails: VisitDetails;
}

export interface VisitDetails {
    country: string;
    countryCode: string;
    region: string;
    city: string;
    ip: string;
    visitorId: string;
    userId: string;
    visitId: string;
    trafficSourceNetwork: string | null;
}

export const getUserSession = async ({
    url,
}: {
    url: string;
}): Promise<{
    data: SessionResponse | null;
    error: Error | null;
}> => {
    const accessToken = getAccessToken();

    const config: AxiosRequestConfig = {
        method: "post",
        url: "/api/session",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
        data: {
            url,
        },
    };

    const { data: sessionResponse, error } = await apiHandler<SessionResponse>(
        () => HTTP.client(config),
    );
    return {
        data: sessionResponse ?? null,
        error,
    };
};
