/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "../styles/globals.scss";
import React, { useState } from "react";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import { Provider as JotaiProvider, useAtom } from "jotai";

import {
    QueryClient,
    QueryClientProvider,
    Hydrate,
    DehydratedState,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import DefaultLayout from "@layout/default";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { getUserSession } from "src/api/session";
import { GQLDomainQuery } from "@gql/types/gql";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import LoaderGlobal from "@components/shared/loaderGlobal";
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type Props = DehydratedState | any;

export default function MyApp({ Component, pageProps }: Props) {
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const queryClient = React.useRef(
        new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: false,
                    refetchOnMount: false,
                },
            },
        }),
    );

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await getUserSession({ url: router.asPath });
            if (data) {
                queryClient.current.setQueryData(["session"], data);
            }
        };

        const handleRouteChange = () => {
            setLoading(true);
            fetchData().catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        };

        const handleRouteChangeError = (
            err: { cancelled: any },
            url: string,
        ) => {
            if (err.cancelled) {
                // eslint-disable-next-line no-console
                console.log(`Route to ${url} was cancelled!`);
            }
        };

        const handleRouteChangeComplete = () => {
            setLoading(false);
        };

        router.events.on("routeChangeStart", handleRouteChange);
        router.events.on("routeChangeComplete", handleRouteChangeComplete);

        router.events.on("routeChangeError", handleRouteChangeError);

        fetchData().catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
            router.events.off("routeChangeError", handleRouteChangeError);
        };
    }, []);

    return (
        <QueryClientProvider client={queryClient.current}>
            <Hydrate state={pageProps.dehydratedState}>
                <JotaiProvider>
                    {loading && <LoaderGlobal />}

                    <DefaultLayout>
                        <Component key={router.asPath} {...pageProps} />
                    </DefaultLayout>
                </JotaiProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </Hydrate>
        </QueryClientProvider>
    );
}
