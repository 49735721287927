/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosError, AxiosPromise } from "axios";
// import { captureException } from "@sentry/nextjs";
const HTTP = {
    server: axios.create({
        baseURL: process.env.BACKEND_URL,
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
        },
    }),
    client: axios.create({
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
        },
    }),
};
export const isAxiosError = (error: unknown | any) => {
    return axios.isAxiosError(error);
};

HTTP.server.interceptors.request.use(
    (config) => {
        config.baseURL = process.env.BACKEND_URL;
        config.headers!["X-SERVER"] = "vercel-server";
        return config;
    },
    (error) => Promise.reject(error),
);

HTTP.client.interceptors.request.use(
    (config) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        config.headers!["X-DOMAIN-NAME"] = process.env.NEXT_PUBLIC_X_DOMAIN!;
        config.baseURL = window.location.origin;
        return config;
    },
    (error) => Promise.reject(error),
);
HTTP.client.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error?.response?.status !== 403) {
            // captureException(error, {
            //     extra: {
            //         frontendUrl:
            //             // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            //             error.response?.config.headers["X-DOMAIN-NAME"],
            //         backendUrl: error.response?.config.url,
            //         headers: error.response?.config.headers,
            //         data: error.response?.config.data,
            //         response: error.response?.data,
            //         status: error.response?.status,
            //     },
            // });
        }

        return Promise.reject(error);
    },
);
HTTP.server.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error?.response?.status !== 403) {
            // captureException(error, {
            //     extra: {
            //         frontendUrl:
            //             // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            //             error.response?.config.headers["X-DOMAIN-NAME"],
            //         backendUrl: error.response?.config.url,
            //         headers: error.response?.config.headers,
            //         data: error.response?.config.data,
            //         response: error.response?.data,
            //         status: error.response?.status,
            //     },
            // });
        }

        return Promise.reject(error);
    },
);

export const apiHandler = async <T>(
    promise: () => AxiosPromise<any>,
): Promise<
    { data: T | null; error: AxiosError } | { data: T; error: null }
> => {
    try {
        const response = await promise();
        return { data: response.data as T, error: null };
    } catch (error) {
        return {
            data: (error as AxiosError<T>).response?.data ?? null,
            error: error as AxiosError,
        };
    }
};

export default HTTP;
