import React, { ReactElement } from "react";
import styles from "./style.module.scss";

const Spinner = ({
    size,
    className = "",
    color,
    success,
}: {
    size: number;
    className?: string;
    color?: string;
    success: boolean;
}): ReactElement => (
    <div
        style={{
            borderColor: !success ? "white" : "transparent",
            borderLeftColor: !success ? color ?? "black" : "transparent",
            width: size ? `${size}px` : undefined,
            height: size ? `${size}px` : undefined,
        }}
        className={`${styles["circle-loader"]} ${
            success ? styles["load-complete"] : ""
        } ${className ?? ""} spinner`}
    >
        <div
            style={{ opacity: success ? 1 : 0, color }}
            className={`${styles["checkmark"]} ${
                success ? styles["draw"] : ""
            }`}
        ></div>
    </div>
);

export default Spinner;
