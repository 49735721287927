const schema = {
    "@context": "https://schema.org",
    "@graph": [
        {
            "@type": "WebPage",
            "@id": "https://torts.com/",
            url: "https://torts.com/",
            name: "Torts.com: Case Acquisition Experts - Quality Source of Mass Tort Leads and Retainers - Torts.com",
            isPartOf: {
                "@id": "https://torts.com/#website",
            },
            about: {
                "@id": "https://torts.com/#organization",
            },
            primaryImageOfPage: {
                "@id": "https://torts.com/#primaryimage",
            },
            image: {
                "@id": "https://torts.com/#primaryimage",
            },
            thumbnailUrl: "https://torts.com/img.jpeg",
            datePublished: "2022-10-30T14:09:20+00:00",
            dateModified: "2023-01-05T23:52:12+00:00",
            description: "Torts.com: Case Acquisition Experts",
            breadcrumb: {
                "@id": "https://torts.com/#breadcrumb",
            },
            inLanguage: "en-US",
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: ["https://torts.com/"],
                },
            ],
        },
        {
            "@type": "ImageObject",
            inLanguage: "en-US",
            "@id": "https://torts.com/#primaryimage",
            url: "https://torts.com/img.jpeg",
            contentUrl: "https://torts.com/img.jpeg",
            width: 259,
            height: 194,
        },
        {
            "@type": "BreadcrumbList",
            "@id": "https://torts.com/#breadcrumb",
            itemListElement: [
                {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home",
                },
            ],
        },
        {
            "@type": "WebSite",
            "@id": "https://torts.com/#website",
            url: "https://torts.com/",
            name: "Quality Source of Mass Tort Leads and Retainers - Torts.com",
            description: "Torts.com: Case Acquisition Experts",
            publisher: {
                "@id": "https://torts.com/#organization",
            },
            potentialAction: [
                {
                    "@type": "SearchAction",
                    target: {
                        "@type": "EntryPoint",
                        urlTemplate:
                            "https://torts.com/?s={search_term_string}",
                    },
                    "query-input": "required name=search_term_string",
                },
            ],
            inLanguage: "en-US",
        },
        {
            "@type": "Organization",
            "@id": "https://torts.com/#organization",
            name: "Quality Source of Mass Tort Leads and Retainers - Torts.com",
            url: "https://torts.com/",
            logo: {
                "@type": "ImageObject",
                inLanguage: "en-US",
                "@id": "https://torts.com/#/schema/logo/image/",
                url: "https://torts.com/logo.svg",
                contentUrl: "https://torts.com/logo.svg",
                caption:
                    "Quality Source of Mass Tort Leads and Retainers - Torts.com",
            },
            image: {
                "@id": "https://torts.com/#/schema/logo/image/",
            },
        },
    ],
};

export default schema;
